<template>
    <a-form ref="formRefPayment" name="dynamic_form_nest_item" :model="dynamicValidateForm" @finish="onFinish"
        style="padding-bottom: 10px;">
        <a-space v-for="(payment, index) in PaymentTermsData" :key="payment.id">
            <a-row class="payments-row">
                <a-col :span="24
                    " v-if="totalTtc > 0 && !isNaN(payment.value)" class="payment-percent-value"><span
                        :title="totalTtc * (payment.value / 100)">{{ totalTtc *
                        (payment.value / 100)
                        }}</span></a-col>
            </a-row>
            <a-row justify="space-between">
                <a-col :span="6">
                    <a-form-item :name="['payments', index, 'value']" :rules="{
                        required: true,
                        message: 'Missing Modality value',
                    }">
                        <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }" :min="0" :max="100"
                            :formatter="(value) => `${value}%`" :parser="(value) => value.replace('%', '')"
                            v-model:value="payment.value" placeholder="Value" />
                    </a-form-item></a-col>

                <a-col :span="6">
                    <a-form-item :name="['payments', index, 'numberOfDays']">
                        <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }" :min="0"
                            v-model:value="payment.numberOfDays" placeholder="Days" />
                    </a-form-item>
                </a-col>

                <a-col :span="8">
                    <a-form-item :name="['payments', index, 'paymentTermOptionId']">
                        <a-select v-if="PaymentTermOptionApi.length" :disabled="readOnlyMode"
                            placeholder="Payment Term option" v-model:value="payment.paymentTermOptionId">
                            <a-select-option v-for="(paymentTerm, index) in PaymentTermOptionApi" :key="index"
                                :value="paymentTerm.id">
                                {{ paymentTerm.name }}</a-select-option>
                        </a-select>
                        <a-select v-else :disabled="readOnlyMode" placeholder="Payment Term option"></a-select>
                    </a-form-item></a-col>
                <a-col :span="3" class="payment-buttons" v-if="!modalityLoading">
                    <sdFeatherIcons v-if="!readOnlyMode" @click="addModality(payment)" type="check-circle" />
                    <sdFeatherIcons v-if="!readOnlyMode" @click="removeModality(payment)" type="minus-circle" />
                </a-col>
                <a-col :span="3" class="payment-buttons" v-else>
                    <a-spin v-if="modalityLoading" />
                </a-col>
            </a-row>
        </a-space>
        <a-button class="add-payment-button" type="dashed" block @click="addPayment" v-if="!readOnlyMode">
            <PlusOutlined />
            Add payment
        </a-button>
    </a-form>
</template>

<script setup>
import { ref, reactive, defineProps, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const { state, dispatch } = useStore();

const props = defineProps(['partnerRequest', "partnerInspectionRequestId", "partnerTransportRequestId", "readOnlyMode"]);

const PaymentTermsData = ref([]);
const PaymentTermOptionApi = computed(() => state.PaymentTermOptionApi.data);
const formRefPayment = ref();
const modalityLoading = ref(false);
const dynamicValidateForm = reactive({
    payments: [],
});


onMounted(() => {
    PaymentTermsData.value = props.partnerRequest.inspectionPaymentTermsList ?? props.partnerRequest.transportPaymentTermsList;
    dispatch("paymentTermOptionGetAllData");
});


const addPayment = () => {
    formRefPayment.value
        .validate()
        .then(async () => {
            PaymentTermsData.value.push({
                transportRequestPartnerId: props.partnerTransportRequestId,
                inspectionRequestPartnerId: props.partnerInspectionRequestId,
                index: PaymentTermsData.value.length,
                id: undefined,
            });
        })
        .catch((error) => {
            console.log("error", error);
        });
};
function sumOfpayment(data) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i].value) {
            sum += parseFloat(data[i].value);
        }
    }
    return sum;
}
const addModality = (item) => {
    var paymentTotal = sumOfpayment(PaymentTermsData.value)
    if (paymentTotal > 100) {
        notification.error({
            message: "Total payment terms exceed 100%",
        });
        return;
    }
    formRefPayment.value
        .validate()
        .then(async () => {
            modalityLoading.value = true;
            try {
                const target = props.partnerInspectionRequestId ? 'InspectionPaymentTerms' : 'TransportPaymentTerms'
                const url = item.id ? `/api/${target}/${item.id}` : `/api/${target}`;
                const response = await (item.id ? DataService.put(url, item) : DataService.post(url, item));
                response.data.index = item.index;
                const foundIndex = PaymentTermsData.value.findIndex((x) => x.id === item.id);
                if (foundIndex !== -1) {
                    PaymentTermsData.value[foundIndex] = response.data;
                }
            } catch (error) {
                notification.error({
                    message: "Error while updating payment terms.",
                });
            }
        })
        .catch(() => {
            notification.error({
                message: "Error updating record.",
            });
        })
        .finally(() => {
            modalityLoading.value = false;

        });
};
const removeModality = async (item) => {
    if (item.id) {
        try {
            const target = props.partnerInspectionRequestId ? 'InspectionPaymentTerms' : 'TransportPaymentTerms'

            await DataService.delete(`/api/${target}/${item.id}`);
            var foundIndex = PaymentTermsData.value.findIndex((x) => x.id == item.id);
            PaymentTermsData.value.splice(foundIndex, 1);
        } catch (error) {
            notification.error({
                message: "Error while updating payment terms.",
            });
        }
    } else {
        let index = PaymentTermsData.value.findIndex(
            (element) => element.index === item.index
        );
        if (index !== -1) {
            PaymentTermsData.value.splice(index, 1);
        }
    }
};
dynamicValidateForm.payments = computed(() =>
    PaymentTermsData.value && PaymentTermsData.value.length
        ? PaymentTermsData.value.map((paymentTerm, key) => {
            return {
                key: key + 1,
                id: paymentTerm.id,
                value: paymentTerm.value,
                detail: paymentTerm.detail,
            };
        })
        : []
);

</script>
<style>
.payment-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 17px;
}

.payment-percent-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding-top: 14px;
}

.add-payment-button {
    margin-top: 40px;
    margin-bottom: 20px;
}
</style>